import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  style: {
    marginRight: '!important',
    marginBottom: '!important',
    color: '#fff !important',
    width: '33.33%',
    borderRadius: '!important',
    backgroundColor:'!important',
    '&:hover': {
      color: '#fff !important',
    },
    textTransform: '!important',
    fontSize: '!important',
    lineHeight:'!important'
  }
}))

export default useStyles
