/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_TRIVIA_QUESTIONS_API_URL

const getAll = async (category, token) => {
  let setToken = `bearer ${token}`
  const config = {
    headers: { Authorization: setToken },
  }

  if (category === null) {
    const request = await axios.get(`${baseUrl}`, config)
    //console.log(request, 'request')
    return { questions: request.data.questions, sessionId: request.data.sessionId }
  } else {
    // send null when category is surprise me
    let updateCategory = category === 'surprise me' ? '' : category
    const request = await axios.get(`${baseUrl}?category=${updateCategory}`, config)
    //console.log(request, 'request')
    return { questions: request.data.questions, sessionId: request.data.sessionId }
  }
}

export default getAll
