/* eslint-disable no-unused-vars */
import axios from 'axios'
import qs from 'qs'

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_TRIVIA_ANSWERS_API_URL

const getCorrectAnswer = async (questionId, answer, token, sessionId) => {
  let setToken = `bearer ${token}`

  const config = {
    headers: {
      Authorization: setToken
    }
  }

  const data = qs.stringify({
    questionId:questionId,
    answer:answer,
    sessionId: sessionId
  })

  try {
    const response = await axios.post(baseUrl,data,config)
    return response.data
  } catch (e) {
    return e
  }
}

export default getCorrectAnswer
