/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import useStyles from './useStyles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { handleQuestions, handleSession } from '../../reducers/QuestionsReducer'
import getAll from '../../services/questions'
import getStats from '../../services/stats'
import MuiAlert from '@material-ui/lab/Alert'
import { addBadge,addProfilePic } from '../../reducers/GameOverReducer'

const breakpoints = createBreakpoints({})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.MuiDialog-paperWidthSm': {
          minWidth: '11cm',
          minHeight: '10cm',
          backgroundColor: '#2a2a2a !important',
          color: '#fff !important',
          [breakpoints.between(300, 541)]: {
            minWidth: '9cm !important',
            minHeight: '10cm !important',
          },
          [breakpoints.down(300)]: {
            minWidth: '7cm !important',
            minHeight: '10cm !important',
          },
          [breakpoints.between(541, 700)]: {
            minWidth: '10cm !important',
            minHeight: '7cm !important',
            overflow: 'auto',
            paddingBottom: '20px',
          },
        },
        '.MuiButton-label': {
        }
      },
    },
  },
})

const DialogTitle = (props) => {
  const { onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const LetsGoModal = ({ color, open, setOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [correctAns, setCorrectAns ] = useState('')
  const [gamesPlayed, setGamesPlayed] = useState('')

  //category selected , get from store
  let category = useSelector(({ misc }) => {
    return misc.category
  })

  //get token from store
  let token = useSelector(({ token }) => {
    return token.token
  })

  //check if user logged
  let logged = useSelector(({ token }) => {
    return token.loggedIn
  })

  const handleClose = () => {
    setOpen(false)
  }

  //questions
  let questions = useSelector(({ questions }) => {
    return questions.questions
  })

  useEffect(() => {
    async function getQuestions() {
      const result = await getAll(category, token)
      dispatch(handleQuestions(result.questions))
      dispatch(handleSession(result.sessionId))
    }
    getQuestions()
  }, [category, dispatch, token])

  useEffect(() => {
    async function getStat() {
      let updateCategory = category === 'surprise me' ? '' : category
      const result = await getStats(updateCategory, token)
      setCorrectAns(result.correctAnswers)
      setGamesPlayed(result.gamesPlayed)
      if (result.user !== undefined) {
        dispatch(addProfilePic(result.user.avatar))
        dispatch(addBadge(result.user.badge))
      }
    }
    getStat()
  }, [])
  
  let message = { category: category }
  useEffect(() => {
    window.top.postMessage(message, '*')
  }, [])

  const handleLogin = () => {
    let message = { action: 'login' }
    window.top.postMessage(message, '*')
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></DialogTitle>
          <Grid container className={classes.firstContent}>
            <Grid item>
              <Typography className={classes.category}>{category}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.stats}>
                Stats:
              </Typography>
            </Grid>
            <Grid container className={classes.secondContent}>
              <Grid item xs={6}>
                <Box className={classes.pointsContainer}>
                  <Typography className={classes.points}>
                    {logged ? correctAns : 0}
                  </Typography>
                  <Typography className={classes.totalPoints}>
                    Total Points
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography className={classes.points}>
                    {logged ? gamesPlayed : 0}
                  </Typography>
                  <Typography className={classes.totalPoints}>
                    Games Played
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.buttonContainer}>
              <Grid item xs={11}>
                {questions.length > 1 ? (
                  <Link
                    to={{
                      pathname: '/play',
                    }}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.button}
                      style={{ backgroundColor: color }}
                    >
                      Let's go
                    </Button>
                  </Link>
                ) : (
                  <Alert severity="error">Getting questions ... </Alert>
                )}
                {logged ? (
                  <Button onClick={handleClose} variant="text" className={classes.cancelButton}>Choose Another</Button>
                ) : (
                  <>
                    <Button onClick={handleClose} variant="text" className={classes.cancelButton}>Choose Another</Button>
                    <Typography
                      className={classes.loginToKeepTypo}
                    >
                      <button onClick={handleLogin} style={{ cursor: 'pointer', background: 'none', color: '#fff', border: 'none' }}>
                      Login to keep your scores!
                      </button>
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default LetsGoModal
