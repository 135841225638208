import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  timerDiv: {
    marginLeft:'10px',
    marginRight:'10px',
    display:'flex',
    justifyContent: 'center',
  }
}))

export default useStyles
