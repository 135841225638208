import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#181818',
    color:'#fff',
    height: '470px',
  },
  triviatime: {
    fontWeight: 'bolder !important',
    textTransform: 'uppercase !important',
    marginTop: '20px',
    marginBottom: '20px !important',
    marginLeft: '10px',
    marginRight: '10px',
    textAlign: 'center',
    fontSize: '1.5rem',
    [theme.breakpoints.down(415)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(380)]: {
      marginTop: '5px',
    },
    color: '#3799cc'
  },
  secondBox: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down(959)]: {
      width: '100%',
    },
  },
  button:{
    height:'60px',
    minWidth:'100%',
    backgroundColor: '#0099cc',
    textTransform: 'capitalize !important',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '-0.5px',
    marginTop:'45px !important',
    [theme.breakpoints.up(959)]: {
      minWidth: '60% !important',
    },
    [theme.breakpoints.down(380)]: {
      marginTop:'20px !important',
      //marginBottom: '1% !important',
    },
    '&:hover': {
      backgroundColor: '#0099cc'
    }
  },
  descrip: {
    marginTop: '20px',
    marginRight: '40px',
    marginLeft: '40px',
    fontSize: '20px',
    justifyContent: 'start',
    [theme.breakpoints.down(400)]: {
      fontSize: '1.2rem',
      marginBottom: '1% !important',
    },
    [theme.breakpoints.down(380)]: {
      marginTop: '5px',
      fontSize: '0.8rem',
    },
  },
  suggestionTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    marginTop: '25px'
  },
  buttonTypog: {
    marginRight: '40px',
    marginLeft: '40px',
    fontSize: '20px',
    justifyContent: 'start',
    textAlign: 'center',
  },
  loginToKeepTypo: {
    textAlign: 'center',
    opacity: '0.9',
    //marginBottom: '20px',
    //marginTop: '-40px',
  },
}))

export default useStyles
