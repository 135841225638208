
const initialState = {
  category: null,
  suggestionTitle: null,
  gameOver: false,
  score: 0,
  questionType: null,
  videoTitle: null,
  videoId: null
}

const MiscReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'ADD_CATEGORY':
    return Object.assign({}, state, {
      category: action.data,
      suggestionTitle: state.suggestionTitle,
      gameOver: state.gameOver,
      score: state.score,
      questionType: state.questionType,
      videoTitle: state.videoTitle
    })

  case 'HANDLE_GAMEOVER':
    return Object.assign({}, state, {
      category: state.category,
      suggestionTitle: state.suggestionTitle,
      gameOver: action.data,
      score: state.score,
      questionType: state.questionType,
      videoTitle: state.videoTitle
    })

  case 'HANDLE_SCORE':
    return Object.assign({}, state, {
      category: state.category,
      suggestionTitle: state.suggestionTitle,
      gameOver: state.gameOver,
      score: action.data,
      questionType: state.questionType,
      videoTitle: state.videoTitle
    })

  case 'HANDLE_TITLE':
    return Object.assign({}, state, {
      category: state.category,
      suggestionTitle: action.data,
      gameOver: state.gameOver,
      score: state.score,
      questionType: state.questionType,
      videoTitle: state.videoTitle
    })

  case 'HANDLE_QUESTIONTYPE':
    return Object.assign({}, state, {
      category: state.category,
      suggestionTitle: state.suggestionTitle,
      gameOver: state.gameOver,
      score: state.score,
      questionType: action.data,
      videoTitle: state.videoTitle
    })

  case 'HANDLE_V_TITLE':
    return Object.assign({}, state, {
      category: state.category,
      suggestionTitle: state.suggestionTitle,
      gameOver: state.gameOver,
      score: state.score,
      questionType: state.questionType,
      videoTitle: action.data
    })

  case 'HANDLE_V_ID':
    return Object.assign({}, state, {
      category: state.category,
      suggestionTitle: state.suggestionTitle,
      gameOver: state.gameOver,
      score: state.score,
      questionType: state.questionType,
      videoTitle: state.videoTitle,
      videoId: action.data
    })

  default:
    return state
  }
}

//adding the category
export const addCategory = (val) => {
  return {
    type: 'ADD_CATEGORY',
    data: val
  }
}

//handle game over
export const handleGameOver = (val) => {
  return {
    type: 'HANDLE_GAMEOVER',
    data: val
  }
}

//handle score
export const handleScore = (val) => {
  return {
    type: 'HANDLE_SCORE',
    data: val
  }
}

//handle suggestion title for keyword trivia
export const addTitle = (val) => {
  return {
    type: 'HANDLE_TITLE',
    data: val
  }
}

//add questiontype
export const addQuesType = (val) => {
  return {
    type: 'HANDLE_QUESTIONTYPE',
    data: val
  }
}

// add videotitle
export const addVideoTitle = (val) => {
  return {
    type: 'HANDLE_V_TITLE',
    data: val
  } 
}

// add videoId
export const addVideoId = (val) => {
  return {
    type: 'HANDLE_V_ID',
    data: val
  } 
}

export default MiscReducer
