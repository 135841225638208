/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import useStyles from './useStyles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { handleGameOver } from '../../reducers/MiscReducer'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const Timer = ({
  time,
  setCurrentQuestion,
  currentQuestion,
  setClicked,
  setSelected,
  clicked,
  seconds,
  setSeconds,
  width,
  setWidth,
  pause,
  setPause,
  logged,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  //get token from store
  let gameOver = useSelector(({ misc }) => {
    return misc.gameOver
  })
  
  useEffect(() => {
    const interval = setInterval(() => {
      runTimeChecker()
    }, 1000)
    return () => clearInterval(interval)
  })

  const runTimeChecker = () => {
    if (!pause && !gameOver) {
      if (seconds < 10) {
        setSeconds((seconds) => seconds + 1)
        setWidth((width) => width + 10)
      }
      if (parseInt(seconds) === parseInt(time)) {
        setSeconds(0)
        setWidth(0)
        setClicked(false)
        setSelected('')
        if (currentQuestion < 9) {
          setCurrentQuestion((currentQuestion) => currentQuestion + 1)
          setClicked(false)
          setSelected('')
        } else {
          setClicked(false)
          setSelected('')
          dispatch(handleGameOver(true))
          history.push({
            pathname: '/score'
          })
        }
      }
    }
  }
  
  return (
    <>
      <CssBaseline />
      <div
        className={classes.timerDiv}
        style={{ marginBottom: logged ? '10px' : '0px' }}
      >
        <Button
          style={{
            width: '90%',
            backgroundColor: '#fff',
            height: '25px',
            borderRadius: '0',
            display: 'flex',
            justifyContent: 'initial',
            padding: '0',
            marginBottom: logged ? '30px' : '0px',
          }}
          disabled={true}
        >
          <div
            style={{
              backgroundColor: '#4198c7',
              width: `${width}%`,
              height: '25px',
            }}
          ></div>
          {
          /* 
            <span style={{ color: '#333', position: 'absolute', marginLeft: '10px' }}>{timeLeft}</span>
          */
          }
        </Button>
      </div>
    </>
  )
}

export default Timer
