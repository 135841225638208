import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute !important',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#fff !important',
  },
  firstContent: {
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '10px',
    textAlign: 'center',
  },
  category: {
    textTransform: 'capitalize',
    fontSize: '30px',
    fontWeight: 'bold',
  },
  stats: {
    textTransform: 'uppercase',
    marginTop: '10px',
    fontSize: '15px',
    color: '#b2b2b2',
  },
  secondContent: {
    display: 'row',
    marginTop: '30px',
  },
  pointsContainer: {
    borderRight: '0.5px solid #b2b2b2',
  },
  totalPoints: {
    color: '#b2b2b2',
    textTransform: 'uppercase',
    marginTop: '20px',
  },
  points: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: '40px',
    justifyContent: 'center',
  },
  button: {
    width: '100%',
    height: '60px',
    textTransform: 'none !important',
    fontSize: '20px',
    borderRadius: '8px',
  },
  loginToKeepTypo: {
    textAlign: 'center',
    //marginTop: '13px',
  },
  cancelButton: {
    marginTop: '5px !important', 
    cursor: 'pointer !important', 
    textTransform: 'capitalize !important', 
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'grey !important'
    }
  }
}))

export default useStyles
