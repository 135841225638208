
const initialState = {
  questions: [],
  sessionId: null
}

const QuestionsReducer = (state = initialState, action) => {
  switch (action.type) {

  case 'GET_QUESTIONS':
    return Object.assign({}, state, {
      questions: action.data,
      sessionId: state.sessionId
    })

  case 'GET_SESSIONID':
    return Object.assign({}, state, {
      questions: state.questions,
      sessionId: action.data
    })

  default:
    return state
  }
}

//get questions
export const handleQuestions = (result) => {
  return async dispatch => {
    dispatch({
      type: 'GET_QUESTIONS',
      data: result
    })
  }
}

//get session Id
export const handleSession = (result) => {
  return async dispatch => {
    dispatch({
      type: 'GET_SESSIONID',
      data: result
    })
  }
}

export default QuestionsReducer
