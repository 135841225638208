import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Category from './components/CategoryLanding/CategoryLanding'
import Play from './components/Play/Play'
import Score from './components/Score/Score'
import Keyword from './components/KeywordLanding/KeywordLanding'

const App = () => {
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
        </Route>
        <Route exact path="/play">
          <Play />
        </Route>
        <Route exact path="/category" >
          <Category />
        </Route>
        <Route exact path="/keyword">
          <Keyword />
        </Route>
        <Route exact path="/score">
          <Score />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
