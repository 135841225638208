/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import useStyles from './useStyles'
import LetsGoModal from '../LetsGoModal/LetsGoModal'
import GeneralButton from '../Button/Button'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addToken } from '../../reducers/TokenReducer'
import { addCategory } from '../../reducers/MiscReducer'
import { handleScore } from '../../reducers/MiscReducer'
import { handleGameOver } from '../../reducers/MiscReducer'
import { useHistory } from 'react-router-dom'
import click from './click.wav'
import { addBadge,addProfilePic } from '../../reducers/GameOverReducer'

const CategoryLanding = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [color, setColor] = useState('')
  //states for to find out, if user clicked front or back button in the browser
  const [ locationKeys, setLocationKeys ] = useState([])

  /**
   * searching for params passed to the category page through iframe
   * this category expects, token and if the user is logged-in or not in the website , through logged param
   */
  const search = useLocation().search

  let differentCategories = [
    { type: 'film', color: '#DCC400' },
    { type: 'tv', color: '#C16F4A' },
    { type: 'music', color: '#e083d3' },
    { type: 'celeb', color: '#9A45AA' },
    { type: 'video games', color: '#528FE0' },
    { type: 'comic', color: '#D35B67' },
    { type: 'anime', color: '#D8AA6E' },
    { type: 'pop culture', color: '#8071D0' },
    { type: 'exclusive', color: '#4DB2A3' },
    { type: 'surprise me', color: '#4098C7' },
  ]
  
  useEffect(() => {
    const token = new URLSearchParams(search).get('token')
    const logged = new URLSearchParams(search).get('loggedIn')
    const category = new URLSearchParams(search).get('category')
    //const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoiNTgzNzcwIiwiYmFkZ2UiOiJodHRwczpcL1wvd20tZGV2LndhdGNobW9qby5jb21cLzIwMTZcL2ltZ1wvcGl4ZWwucG5nIiwiYXZhdGFyIjoiaHR0cHM6XC9cL2xoNS5nb29nbGV1c2VyY29udGVudC5jb21cLy1RWnRKdFJaLXd6Z1wvQUFBQUFBQUFBQUlcL0FBQUFBQUFBQUFBXC9BTVp1dWNtZlBLQ0FDMWxOZWJEWk45cmQ2czFwYWoyTUVnXC9zOTYtY1wvcGhvdG8uanBnIiwibmFtZSI6IkhlYXJ0IHZzIE1pbmQifSwibGFuZyI6ImVuIiwic3VnZ2VzdGlvbklkIjpudWxsLCJ2aWRlb0lkIjpudWxsLCJpcCI6Ijk5LjIzNC4xMzQuMTA0IiwiaWF0IjoxNjM5MDcwNDkyLCJleHAiOjE2MzkxNTY4OTJ9.PZjE8X8gjG5VH1tLu768Y9a3yEOT9dAlADZ90-050_I'
    //const logged = true
    
    if(category !== null){
      differentCategories.map(item => {
        if(item.type === category.toLowerCase()){
          setOpen(true)
          dispatch(addCategory(category.toLowerCase()))
          setColor(item.color)
        }
      })
    }
    dispatch(addToken(token, logged))
  }, [])

  useEffect(() => {
    dispatch(handleScore(0))
    dispatch(handleGameOver(false))
    dispatch(addProfilePic(null))
    dispatch(addBadge(null))
  }, [])

  // sending resize action to iframe for resizing iframe in php, based on the height of this category page
  useEffect(() => {
    let message = { action: 'resize', height : '610px' }
    //method to send message to parent outside iframe
    window.top.postMessage(message, '*')
  }, [])

  useEffect(() => {
    let message = { action: 'onload' }
    window.top.postMessage(message, '*')
  }, [])

  //getting the category from the redux store
  let category = useSelector(({ misc }) => {
    return misc.category
  })

  // getting suggestionTitle from redux store
  let suggestionTitle = useSelector(({ misc }) => {
    return misc.suggestionTitle
  })

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)

          // Handle forward event
          // redirecting users to specific home page for category or keyword, if they press browser forward button
          category !== null ? window.top.location.href=`${process.env.REACT_APP_WATCHMOJO_URL}play` :  window.top.location.href=`${process.env.REACT_APP_WATCHMOJO_URL}suggest`

        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          // Handle back event
        }
      }
    })
  }, [ locationKeys, ])
  
  /** 
   * activate modal if parent url contains category already
   * check the category type and pass it
  */

  const openLetsGo = (event) => {
    let sound = new Audio(click)
    sound.play()
    setOpen(true)
    dispatch(addCategory(event.currentTarget.value))
    setColor(event.currentTarget.name)
  }

  return (
    <>

      <CssBaseline />
      <div className={classes.firstBox}>
        <Typography className={classes.triviaTime}>Trivia Time!</Typography>
        <Typography className={classes.info}>
            Select the category you want to play in below and we'll curate 10
            questions related to it!
        </Typography>
        <Box className={classes.firstLine}>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'film'}
            color={'#DCC400'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            marginRight={'16px'}
            borderRadius={'8px'}
          >
              Film
          </GeneralButton>

          <GeneralButton
            openLetsGo={openLetsGo}
            category={'tv'}
            color={'#C16F4A'}
            width={'33.33%'}
            textTransformation={'uppercase'}
            marginRight={'16px'}
            borderRadius={'8px'}
          >
              Tv
          </GeneralButton>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'music'}
            color={'#e083d3'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            borderRadius={'8px'}
          >
              Music
          </GeneralButton>
        </Box>
        <Box className={classes.firstLine}>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'celeb'}
            color={'#9A45AA'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            marginRight={'16px'}
            borderRadius={'8px'}
          >
              Celeb
          </GeneralButton>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'video games'}
            color={'#528FE0'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            marginRight={'16px'}
            borderRadius={'8px'}
          >
              Video Games
          </GeneralButton>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'comic'}
            color={'#D35B67'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            borderRadius={'8px'}
          >
              Comic
          </GeneralButton>
        </Box>
        <Box className={classes.firstLine}>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'anime'}
            color={'#D8AA6E'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            marginRight={'16px'}
            borderRadius={'8px'}
          >
              Anime
          </GeneralButton>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'pop culture'}
            color={'#8071D0'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            marginRight={'16px'}
            borderRadius={'8px'}
          >
              Pop Culture
          </GeneralButton>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'exclusive'}
            color={'#4DB2A3'}
            width={'33.33%'}
            textTransformation={'capitalize'}
            borderRadius={'8px'}
          >
              Exclusive
          </GeneralButton>
        </Box>
        <Box className={classes.surpriseMeBox}>
          <GeneralButton
            openLetsGo={openLetsGo}
            category={'surprise me'}
            color={'#4098C7'}
            height={'64px'}
            width={'100%'}
            textTransformation={'capitalize'}
            borderRadius={'8px'}
          >
              Surprise Me!
          </GeneralButton>
        </Box>
      </div>
      {open === true ? (
        <LetsGoModal color={color} open={open} setOpen={setOpen} />
      ) : (
        ''
      )}
     
    </>
  )
}

export default CategoryLanding
