/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import useStyles from './useStyles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import GeneralButton from '../Button/Button'
import Timer from '../Timer/Timer'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import TFAnswerComponent from '../TFAnswerComponent/TFAnswerComponent'
import { useDispatch, useSelector } from 'react-redux'
import getCorrectAnswer from '../../services/answers'
import { handleAnswers, handleCorrectAns } from '../../reducers/AnswersReducer'
import { handleScore } from '../../reducers/MiscReducer'
import MuiAlert from '@material-ui/lab/Alert'
import Stack from '@mui/material/Stack'
import SnackbarContent from '@mui/material/SnackbarContent'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}


const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.MuiButton-label': {
          letterSpacing: '0.0px',
          fontSize: '17px',
          fontWeight: '500'
        }
      },
    },
  },
})

const Play = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [questionId, setQuestionId] = useState()
  const [clicked, setClicked] = useState(false)
  const [selected, setSelected] = useState('')
  const [seconds, setSeconds] = useState(0)
  const [width, setWidth] = useState(0)
  const [pause, setPause] = useState(false)
  const [thumbnail, setThumbnail] = useState('')

  let time = 10
  let timeLeft = (time - seconds)

  const dispatch = useDispatch()

  const classes = useStyles({ thumbnail: thumbnail })

  //questions
  let questions = useSelector(({ questions }) => {
    return questions.questions
  })

  //console.log('questions', questions)

  //sessionId
  let sessionId = useSelector(({ questions }) => {
    return questions.sessionId
  })

  //get token from store
  let token = useSelector(({ token }) => {
    return token.token
  })
  
  //get answers from store
  let answers = useSelector(({ answers }) => {
    return answers.answers
  })

  //get correct answer from store
  let correctAnswer = useSelector(({ answers }) => {
    return answers.correctAnswer
  })

  //category from store
  let category = useSelector(({ misc }) => {
    return misc.category
  })

  //suggestion title from store
  let suggestionTitle = useSelector(({ misc }) => {
    return misc.suggestionTitle
  })

  //get score from the store
  let score = useSelector(({ misc }) => {
    return misc.score
  })

  //get type of the question if set , from the store
  let questionType = useSelector(({ misc }) => {
    return misc.questionType
  })

  //pausing the timer., testing purpose
  const pauseTimer = () => {
    pause ? setPause(false) : setPause(true)
  }

  useEffect(() => {
    let message = { action: 'startGame', category: category }
    window.top.postMessage(message, '*')
  }, [])

  useEffect(() => {
    let message = { action: 'resize', height: '750px' }
    window.top.postMessage(message, '*')
  }, [])

  //emptying answers and correct answer after each question changes
  useEffect(() => {
    dispatch(handleCorrectAns([]))
    dispatch(handleAnswers([]))
  }, [currentQuestion, dispatch])

  useEffect(() => {
    if (questions.length > 0) {
      setThumbnail(questions[currentQuestion].thumbnail)
      setQuestionId(questions[currentQuestion].id)
    }
  }, [currentQuestion, questions])

  const getPercentage = (answerId) => {
    const result = answers.filter((item) => item.id === answerId)
    return result[0].percentage
  }

  //when answers clicked
  const handleClick = (event) => {
    let currentSelected = event.currentTarget.value

    //get correct answer
    async function getCorrect() {
      const response = await getCorrectAnswer(
        questionId,
        event.currentTarget.value,
        token,
        sessionId
      )

      const correctAns = response.filter((item) => item.correctAnswer)
      //store all the answers
      dispatch(handleAnswers(response))
      //store the correct answer
      dispatch(handleCorrectAns(correctAns))

      if (questionType === undefined) {
        if (parseInt(currentSelected) === correctAns[0].id) {
          let newScore = score + 1
          dispatch(handleScore(newScore))
        }
      } else {
        if (JSON.parse(currentSelected) === correctAns[0].answer) {
          let newScore = score + 1
          dispatch(handleScore(newScore))
        }
      }
    }
    getCorrect()
    setClicked(true)
    setSelected(event.currentTarget.value)
    setSeconds(7)
    if (seconds < 7) {
      setWidth(70)
    }
  }

  // if user does not click anything in 10 seconds
  useEffect(() => {
    async function getCorrect() {
      const response = await getCorrectAnswer(
        questionId,
        'ok',
        token,
        sessionId
      )
      const correctAns = response.filter((item) => item.correctAnswer)
      dispatch(handleAnswers(response))
      dispatch(handleCorrectAns(correctAns))
    }
    if (seconds === 10 && clicked === false) {
      setPause(true)
      getCorrect()

      setTimeout(() => {
        if (seconds === 10 && clicked === false) {
          setPause(false)
        }
      }, 2000)
    }
  }, [seconds])

  //color for countdown
  const countDownColor = () => {
    let colorCode
    switch (timeLeft) {
    case 10:
      colorCode = '#DCC400'
      break
    case 9:
      colorCode = '#C16F4A'
      break
    case 8:
      colorCode = '#e083d3'
      break
    case 7:
      colorCode = '#9A45AA'
      break
    case 6:
      colorCode = '#528FE0'
      break
    case 5:
      colorCode = '#D35B67'
      break
    case 4:
      colorCode = '#D8AA6E'
      break
    case 3:
      colorCode = '#8071D0'
      break
    case 2:
      colorCode = '#4DB2A3'
      break
    default:
      colorCode = ''
      break
    }
    return colorCode
  }

  return (
    questions.length > 0 ? (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.firstBox} >
            <div className={classes.backgroundImgDiv}>
              <Box className={classes.questionBoxOuter}>
                { 
                  category !== null ? 
                    <Typography variant="body2">Trivia from <span className={classes.triviaFromTitle}>{category}</span></Typography>
                    : 
                    <>
                      <Typography variant="body2">Trivia from</Typography>
                      <Typography className={classes.triviaFromTitle}>
                        {questions[currentQuestion].videoTitle}
                      </Typography>
                      {/* <Typography className={classes.triviaFromTitle}>
                        {questions[currentQuestion].videoTitle}
                      </Typography> */}
                    </>
                }
                <Typography className={classes.questionNumber}>
                TRIVIA Question {currentQuestion + 1}/10
                </Typography>
                <div className={classes.questionBox}>
                  <Typography className={classes.question}>
                    {questions[currentQuestion].question}
                  </Typography>
                </div>
                {/*
                  <GeneralButton openLetsGo={pauseTimer} color={'green'}>
                  Pause
                  </GeneralButton>
                  */
                }
              </Box>
            </div>
            <>
              <div className={classes.answersDiv}>
                {questions && questions[currentQuestion].type == 'multi' ? (
                  questions[currentQuestion].answers.map((option) => {
                    return (
                      <GeneralButton
                        height={'50px'}
                        width={'90%'}
                        color={'#4198c7'}
                        textTransformation={'capitalize'}
                        marginBottom={'15px'}
                        marginRight={'15px'}
                        fontSize={'16px'}
                        lineHeight={'0.9'}
                        fontWeight={500}
                        openLetsGo={handleClick}
                        category={option.id}
                        key={option.id}
                        clicked={clicked}
                        selected={selected}
                        correctAnswer={correctAnswer}
                        pause={pause}
                        seconds={seconds}
                      >
                        {(clicked === false && pause === false) ? (
                          option.answer
                        ) : (clicked || pause) && correctAnswer.length !== 1 ? (
                          <CircularProgress />
                        ) : (
                          <>
                            {(clicked || pause) && option.id === correctAnswer[0].id ? (
                              <>
                                <Box display='flex' justifyContent='space-between' mt={1}>
                                  {`${option.answer}`} <span dangerouslySetInnerHTML={{ __html: '&horbar;' }} className={classes.answerStyle} />{`${getPercentage(
                                    option.id
                                  )}%`}
                                  <span className={classes.answerIcons}><CheckCircleIcon /></span>
                                </Box>
                              </>
                            ) : (clicked || pause) &&
                            option.id !== correctAnswer[0].id &&
                            // eslint-disable-next-line eqeqeq
                            (selected == option.id || pause) ? (
                                <>
                                  <Box display='flex' justifyContent='space-between' mt={1}>
                                    {`${option.answer}`} <span dangerouslySetInnerHTML={{ __html: '&horbar;' }} className={classes.answerStyle} />{`${getPercentage(
                                      option.id
                                    )}%`}
                                    <span className={classes.answerIcons}><CancelIcon /></span>
                                  </Box>
                                </>
                              ) : (clicked || pause) &&
                              option.id !== correctAnswer[0].id &&
                            (selected !== option.id || pause) ? (
                                  <> 
                                    <Box display='flex' justifyContent='space-between' mt={1}>
                                      {`${option.answer}`} <span dangerouslySetInnerHTML={{ __html: '&horbar;' }} className={classes.answerStyle} />{`${getPercentage(
                                        option.id
                                      )}%`}
                                    </Box>
                                  </>
                                ) : (
                                  option.answer
                                )}
                          </>
                        )}
                      </GeneralButton>
                    )
                  })
                ) : (
                // if question type is tf , then show this component of two true false buttons is shown 
                  <TFAnswerComponent
                    handleClick={handleClick}
                    clicked={clicked}
                    selected={selected}
                    pause={pause}
                    seconds={seconds}
                  />
                )}
              </div>
              <Box textAlign='center' style={{ minHeight: '22px', height: '22px', marginBottom: '15px' }}>
                {
                  (timeLeft > 1 && clicked === false) 
                    ? 
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography>
                        <span style={{ color: countDownColor(), fontWeight: 'bold', fontSize: '25px' }}>{timeLeft} </span>
                      </Typography>
                      <div style={{ display: 'inline', paddingTop: '8px', fontSize: '15px', paddingLeft: '10px' }}><span>seconds left</span></div>
                    </div>
                      
                    :  (timeLeft === 1 && clicked === false) 
                      ? 
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography>
                          <span style={{ color: 'red', fontWeight: 'bold', fontSize: '25px' }}>{timeLeft} </span>
                        </Typography>
                        <div style={{ display: 'inline', paddingTop: '8px', fontSize: '15px', paddingLeft: '10px' }}><span>second left</span></div>
                      </div>
                      : ''} 
               
              </Box>
              <Timer
                time={10}
                setCurrentQuestion={setCurrentQuestion}
                currentQuestion={currentQuestion}
                questions={questions}
                score={score}
                setClicked={setClicked}
                setSelected={setSelected}
                clicked={clicked}
                seconds={seconds}
                setSeconds={setSeconds}
                width={width}
                setWidth={setWidth}
                pause={pause}
                setPause={setPause}
              />
            </>
          </div>
        </ThemeProvider>
      </>
    ) : (
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' className={classes.gameCompletedBox} >
        { /* if questions are not available */ }
        <CircularProgress />
        <Stack spacing={2} sx={{ maxWidth: 600 }} style={{ marginTop: '25px' }}>
          <SnackbarContent message="Game already completed, Start a new one" />
        </Stack>
      </Box>
    )
  )
}

export default Play
