/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import useStyles from './useStyles'
import Button from '@material-ui/core/Button'
import { addQuesType } from '../../reducers/MiscReducer'
import { useDispatch } from 'react-redux'

/**
 *
 * @param {*}
 * This is the general button used, accepts the following props
 * @params height , width, textTransformation, color, openLetsGo, category, marginButton, marginBottom, marginTop, marginLeft, borderRadius, fontSize, fontWeight, lineHeight, correctAnswer, clicked,
 * selected, type, children
 * @returns
 */
const GeneralButton = ({
  height,
  width,
  textTransformation,
  color,
  openLetsGo,
  category,
  marginRight,
  marginBottom,
  marginTop,
  marginLeft,
  borderRadius,
  lineHeight,
  correctAnswer,
  clicked,
  selected,
  type,
  pause,
  seconds,
  children,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  //adding the question type to the reducer store
  useEffect(() => {
    dispatch(addQuesType(type))
  }, [dispatch, type])
  
  return (
    <>
      <CssBaseline />
      <Button
        className={classes.style}
        onClick={openLetsGo}
        value={category}
        name={color}
        style={{
          backgroundColor:
            (type === undefined)
              ? (clicked === false && pause === false)
                ? color
                : ((clicked || pause) && correctAnswer.length !== 1)
                  ? color
                  : ((clicked || pause) && category === correctAnswer[0].id)
                    ? '#5CB85C'
                    : ((clicked || pause) &&
                  category !== correctAnswer[0].id &&
                  (selected == category || pause))
                      ? '#F23C34'
                      : ((clicked || pause) &&
                  category !== correctAnswer[0].id &&
                  category != selected)
                        ? color
                        : color
              : (clicked === false && pause === false)
                ? color
                : ((clicked || pause) && correctAnswer.length !== 1)
                  ? color
                  : ((clicked || pause) && JSON.parse(category) === correctAnswer[0].answer)
                    ? '#5CB85C'
                    : ((clicked || pause) && JSON.parse(category) !== correctAnswer[0].answer)
                      ? '#F23C34'
                      : color,
          height:
            (height !== undefined)
              ? height
              : (window.screen.availWidth < 365)
                ? '70px'
                : '99px',
          width: width,
          textTransform: textTransformation,
          marginRight: marginRight,
          marginBottom: marginBottom,
          borderRadius: borderRadius,
          marginTop: marginTop,
          marginLeft: marginLeft,
          lineHeight: lineHeight,
          opacity:
            (type === undefined)
              ? (clicked === false && pause === false)
                ? '100%'
                : ((clicked || pause) && correctAnswer.length !== 1)
                  ? '100%'
                  : // eslint-disable-next-line eqeqeq
                  (clicked &&
                  category === correctAnswer[0].id &&
                  category == selected)
                    ? '100%'
                    : (clicked &&
                  category !== correctAnswer[0].id &&
                  category == selected)
                      ? '100%'
                      : ((clicked || pause )&&
                  category !== correctAnswer[0].id &&
                  ( category != selected || pause ))
                        ? '60%'
                        : '100%'
              : '100%',
          letterSpacing: '-0.5px',
          fontSize: '15px',
          fontWeight: 600
        }}
        disabled={(clicked || pause || ( seconds === 10 )) ? true : false}
      >
        {children}
      </Button>
    </>
  )
}

export default GeneralButton
