/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_TRIVIA_STATS_API_URL

const getStats = async (category, token) => {

  let setToken = `bearer ${token}`

  const config = {
    headers: { Authorization: setToken },
  }
  try {
    const response = await axios.get(`${baseUrl}?category=${category}`, config)
    return response.data
  }catch (error) {
    return error.message
  }
  // const response = await axios.get(`${baseUrl}?category=${category}`, config)
  // console.log('response for stats', response)
  // return response.data
}

export default getStats
