import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({

  firstBox: {
    display:'flex',
    flexDirection:'column',
    backgroundColor: '#181818',
    color: '#fff',
    boxShadow: '1px #252525',
    height: window.screen.height,
    overflow: 'hidden',
    maxHeight: '750px',
    [theme.breakpoints.up(800)]: {
      maxHeight: '820px',
    },
    [theme.breakpoints.between(1000,1024)]: {
    },
  },

  backgroundImgDiv: {
    minWidth: '100%',
    position: 'relative',
    [theme.breakpoints.down(375)]: {
      height: '190px'
    },
    [theme.breakpoints.between(376,700)]: {
      height: '250px'
    },
    '&::before': {
      content: '\'\'',
      display: 'block',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      filter: 'brightness(40%)',
      backgroundImage: props => `url(${props.thumbnail})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
  },
  questionBoxOuter: {
    position: 'relative',
    marginLeft: '8%',
    paddingTop: '1%',
    marginRight: '8%',
    paddingBottom: '1%'
  },
  questionBox: {
    border: '2px solid #55b5ef',
    marginBottom: '20px',
    paddingTop: '2%',
    paddingLeft: 17,
    paddingRight: 7,
    paddingBottom: '2%',
    minHeight: '60px',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  questionNumber: {
    color: '#55b5ef',
    fontWeight: 'bold',
    //marginTop: '2%',
    //marginBottom: '1%',
    margin: '10% 0 -5px 0px',
    fontSize: '18px',
    [theme.breakpoints.down(700)]: {
    },
  },
  triviaFromTitle: {
    fontWeight: 'bold',
    textTransform: 'capitalize !important'
  },
  question: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '1.2',
    [theme.breakpoints.down(700)]: {
      fontSize: '19px'
    },
  },
  answersDiv: {
    marginLeft: '20px',
    marginTop: '5%',
    marginBottom: '2%',
    textAlign: 'center',
    minHeight: '260px',
    [theme.breakpoints.down(600)]: {
      minHeight: '250px',
    },
  },
  select: {
    backgroundColor: 'green !important',
    color: '#fff',
  },
  wrong: {
    backgroundColor: 'red !important',
    color: '#fff',
  },
  normal: {
    backgroundColor: 'blue !important',
  },
  answerStyle: {
    marginRight: '4px', 
    marginLeft: '4px'
  },
  answerIcons: {
    marginLeft: '10px', 
    marginTop: '-5px'
  },
  loginToKeepTypo: {
    textAlign: 'center',
    opacity: '0.7',
    marginBottom: '20px',
    marginTop: '10px',
  },
  gameCompletedBox: {
    height: '100vh'
  }
}))

export default useStyles
