/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_TRIVIA_FINISHGAME_API_URL

const finish = async (token, sessionId) => {
  let setToken = `bearer ${token}`

  const config = {
    headers: { Authorization: setToken },
  }

  const response = await axios.get(`${baseUrl}?sessionId=${sessionId}`, config)

  return response.data
}

export default finish
