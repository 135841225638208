import CssBaseline from '@material-ui/core/CssBaseline'
import GeneralButton from '../Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { useSelector } from 'react-redux'

const TFAnswerComponent = ({ handleClick, clicked, selected, pause,seconds }) => {
  //get answers from store
  let answers = useSelector(({ answers }) => {
    return answers.answers
  })

  //get correct answer from store
  let correctAnswer = useSelector(({ answers }) => {
    return answers.correctAnswer
  })

  const getPercentage = (answer) => {
    const result = answers.filter((item) => item.answer === JSON.parse(answer))
    return result[0].percentage
  }

  const options = ['true', 'false']

  return (
    <>
      <CssBaseline />
      {options.map((option) => {
        return (
          <GeneralButton
            height={'50px'}
            width={'90%'}
            color={'#4198c7'}
            textTransformation={'capitalize'}
            marginBottom={'15px'}
            marginRight={'15px'}
            fontSize={'16px'}
            lineHeight={'0.9'}
            fontWeight={500}
            openLetsGo={handleClick}
            category={option}
            key={option}
            clicked={clicked}
            selected={selected}
            correctAnswer={correctAnswer}
            type={'tf'}
            pause={pause}
            seconds={seconds}
          >
            {(clicked === false && pause === false ) ? (
              option
            ) : (clicked || pause) && correctAnswer.length !== 1 ? (
              <CircularProgress />
            ) : (
              <>
                {(clicked || pause) && JSON.parse(option) === correctAnswer[0].answer ? (
                  <>
                    <Grid container style={{ marginTop: '5px' }}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={7} mt={1}>
                        {`${option}`}{' '}
                        <span
                          dangerouslySetInnerHTML={{ __html: '&horbar;' }}
                          style={{ marginRight: '4px', marginLeft: '4px' }}
                        />
                        {`${getPercentage(option)}%`}
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '-5px' }}>
                        <CheckCircleIcon />
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container style={{ marginTop: '5px' }}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={7} mt={1}>
                        {`${option}`}{' '}
                        <span
                          dangerouslySetInnerHTML={{ __html: '&horbar;' }}
                          style={{ marginRight: '4px', marginLeft: '4px' }}
                        />
                        {`${getPercentage(option)}%`}
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '-5px' }}>
                        <CancelIcon />
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </GeneralButton>
        )
      })}
    </>
  )
}

export default TFAnswerComponent
