import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: '#181818',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    color:'#fff',
    [theme.breakpoints.up(959)]: {
    }
  },
  avatar: {
    height: '110px !important',
    width: '110px !important'
  },
  finalScore: {
    marginTop: '10px',
    textTransform: 'uppercase',
    fontWeight: '500',
    color: '#efefef !important',
    fontSize: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down(959)]: {
      fontSize: '1.5rem',
    }
  },
  badge: {
    height: '35px',
    filter: 'invert(1)',
    marginLeft: '-20px',
    marginTop: '-14px'
  },
  stats: {
    marginTop: '20px',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: '#efefef !important',
    fontSize: '1.3rem',
    opacity: '0.8',
    [theme.breakpoints.down(959)]: {
      fontSize: '1rem',
    }
  },
  statsDetails: {
    marginTop: '10px',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: '#efefef !important',
    fontSize: '1.7rem'
  }
}))

export default useStyles
