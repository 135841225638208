/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from './useStyles'
import Avatar from '@material-ui/core/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { handleQuestions, handleSession } from '../../reducers/QuestionsReducer'
import Button from '@material-ui/core/Button'
import {
  RedditIcon,
  RedditShareButton,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  ViberIcon,
  ViberShareButton
} from 'react-share'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Snackbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useHistory } from 'react-router-dom'
import Confetti from 'react-confetti'
import { addToken } from '../../reducers/TokenReducer'
import { addTitle } from '../../reducers/MiscReducer'
import { addBadge,addProfilePic } from '../../reducers/GameOverReducer'
import finish from '../../services/finish'
import urlencode from 'locutus/php/url/urlencode'
import { is_null } from 'locutus/php/var'

const Score = () => {

  const [locationKeys, setLocationKeys] = useState([])

  //state for snackbar
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  const [correctAns, setCorrectAns ] = useState('')
  const [gamesPlayed, setGamesPlayed ] = useState('')

  //watchmojo url 
  let watchmojoUrl = process.env.REACT_APP_WATCHMOJO_URL

  //category from store
  let category = useSelector(({ misc }) => {
    return misc.category
  })

  //sessionId
  let sessionId = useSelector(({ questions }) => {
    return questions.sessionId
  })

  //suggestion title from store
  let suggestionTitle = useSelector(({ misc }) => {
    return misc.suggestionTitle
  })

  //getting video Title from the redux store
  let videoTitle = useSelector(({ misc }) => {
    return misc.videoTitle
  })

  //getting videoId from the redux store
  let videoId = useSelector(({ misc }) => {
    return misc.videoId
  })

  //category from store
  let score = useSelector(({ misc }) => {
    return misc.score
  })

  //get token from store
  let token = useSelector(({ token }) => {
    return token.token
  })

  //check if user is logged in or not
  let loggedIn = useSelector(({ token }) => {
    return token.loggedIn
  })

  let gameOver = useSelector(({ misc }) => {
    return misc.gameOver
  })
  
  //after game is over , get the following from the store
  let badge = useSelector(({ gameOver }) => {
    return gameOver.badge
  })
  let profilePic = useSelector(({ gameOver }) => {
    return gameOver.profilePic
  })

  /** 
   * video game play ,
   * 1. video title 
   * 2. play again should redirect to that video page 
  */

  /**
   * function for determining height of the score page 
   * @param {*} loggedIn 
   * @param {*} suggestionTitle 
   * @returns height
   */
  const lastPageHeight = (loggedIn, suggestionTitle) => {
    let height
    if((loggedIn === false || loggedIn === null) && suggestionTitle === null){
      height = '645px'
    }
    if ((loggedIn === false || loggedIn === null) && suggestionTitle !== null){
      height =  '588px'
    }
    if(loggedIn && suggestionTitle === null){
      height =  '570px'
    }
    if(loggedIn && suggestionTitle !== null){
      height =  '418px'
    }
    return height
  }

  //keyword trivia title encoding
  let linkForKeyword = suggestionTitle !== null ?  urlencode(suggestionTitle) : urlencode(videoTitle)
  /**
   * function for copying the score details to clipboard
   */
  const handleCopyToClipboard = () => {
    let textField = document.createElement('textarea')
    let textToCopy

    setOpen(true)
    category !== null ?
      textToCopy = `${watchmojoUrl}play/${category}`
      :
      videoId !== null ? textToCopy = encodeURI(`${watchmojoUrl}play/${videoId}`)
        : textToCopy = `${watchmojoUrl}play/${linkForKeyword}`
    textField.innerText = textToCopy
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  /**
   * function takes care of playagain action
   */
  const handlePlayAgain = () => {
    dispatch(addToken(token, loggedIn))
    dispatch(addTitle(suggestionTitle))
    category !== null
      ? (window.top.location.href = `${watchmojoUrl}play`)
      : suggestionTitle !== null
        ? (window.top.location.href = `${watchmojoUrl}suggest`)
        : (window.top.location.href = `${watchmojoUrl}`)
  }

  //button inside clipboard notification
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  //clipboard copy notification 
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )
  
  //run this after score page is mounted
  useEffect(() => {
    async function endGame() {
      const result = await finish(token, sessionId)
      setCorrectAns(result.correctAnswers)
      setGamesPlayed(result.gamesPlayed)
      if (result.user !== undefined) {
        dispatch(addProfilePic(result.user.avatar))
        dispatch(addBadge(result.user.badge))
      }
    }
    endGame()
  }, [])

  useEffect(() => {
    dispatch(handleQuestions([]))
    dispatch(handleSession(null))
  }, [])
  
  const handleLogin = () => {
    let message = { action: 'login' }
    window.top.postMessage(message, '*')
  }

  useEffect(() => {
    // category null when it is surprise me.,
    let updateCategory = category === 'surprise me' ? '' : category
    let message = { action: 'finishGame', category: updateCategory, height: lastPageHeight(loggedIn, suggestionTitle) }
    window.top.postMessage(message, '*')
  }, [])

  useEffect(() => {
    let message = { action: 'resize', height: lastPageHeight(loggedIn, suggestionTitle) }
    window.top.postMessage(message, '*')
  }, [])

  const history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys])

          // Handle back event
          //if back button is clicked in the browser user is redirected as follows
          category !== null
            ? (window.top.location.href =
                `${watchmojoUrl}play`)
            : (window.top.location.href =  `${watchmojoUrl}suggest`)
        }
      }
    })
  }, [locationKeys])

  return (
    <>
      <CssBaseline />
      {
        score > 4 ?
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          :
          ''
      }
      <div
        className={classes.mainBox}
        style={{ height: lastPageHeight(loggedIn, suggestionTitle) }}
      >
        {( suggestionTitle === null ) ? (
          <Box style={{ marginTop: loggedIn ? '40px' : '0px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {loggedIn === false || loggedIn === null ? (
                ''
              ) : (
                <Avatar className={classes.avatar} src={profilePic} />
              )}
              {(loggedIn !== false || loggedIn !== null) && (
                <img className={classes.badge} src={badge} />
              )}
            </div>
          </Box>
        ) : (
          ''
        )}
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {suggestionTitle === null ? (
            <Typography
              variant="h4"
              className={classes.finalScore}
              textAlign="center"
            >
              Your Final Score: {score}/10
            </Typography>
          ) : (
            ''
          )}
          <Typography variant="h4" className={classes.stats}>
            {category !== null ? category : 'GAME'} Stats:
          </Typography>
          <Box
            style={{
              borderTop: '0.1px solid grey',
              width: '90%',
              borderBottom: '0.1px solid grey',
            }}
            display="flex"
            justifyContent="center"
            mt={2}
            pb={3}
            pt={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pr={4}
              style={{ borderRight: '0.1px solid grey' }}
            >
              {suggestionTitle === null ? (
                <Typography variant="h4" className={classes.statsDetails}>
                  {correctAns}
                </Typography>
              ) : (
                <Typography variant="h4" className={classes.statsDetails}>
                  {score}/10
                </Typography>
              )}
              {suggestionTitle === null ? (
                <Typography
                  variant="h4"
                  className={classes.stats}
                  style={{ alignSelf: 'flex-start' }}
                >
                  Total Points
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  className={classes.stats}
                  style={{ alignSelf: 'flex-start' }}
                >
                  CORRECT
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pl={3}
            >
              <Typography variant="h4" className={classes.statsDetails}>
                {gamesPlayed}
              </Typography>
              <Typography variant="h4" className={classes.stats}>
                Games Played
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            style={{
              backgroundColor: '#4198c7',
              width: '90%',
              height: '50px',
              color: '#fff',
              textTransform: 'none',
              borderRadius: '8px'
            }}
            onClick={handlePlayAgain}
          >
                  Play again
          </Button>
        </Box>
        <Box textAlign="center" mt={3}>
          <Typography variant="body2" textAlign="center">
                Share your score!
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={1}
          textAlign="center"
        >
          <TwitterShareButton
            title={category !== null ?  `I tested my trivia power in the ${category} category and scored ${score}/10! Test your trivia power too!` : `I tested my trivia power and scored ${score}/10! Test your trivia power too!`}
            url={category !== null ? encodeURI(`${watchmojoUrl}play/${category}`) :  videoId !== null ?  encodeURI(`${watchmojoUrl}play/${videoId}`) : `${watchmojoUrl}play/${linkForKeyword}`}
            style={{ marginRight: '10px' }}
          >
            <TwitterIcon size={40} round={false} />
          </TwitterShareButton>

          <FacebookShareButton
            quote={category !== null ? `I tested my trivia power in the ${category} category and scored ${score}/10! Test your trivia power too!` : `I tested my trivia power and scored ${score}/10! Test your trivia power too!`}
            url={category !== null ? encodeURI(`${watchmojoUrl}play/${category}`) :  videoId !== null ?  encodeURI(`${watchmojoUrl}play/${videoId}`) : `${watchmojoUrl}play/${linkForKeyword}`}
            style={{ marginRight: '10px' }}
          >
            <FacebookIcon size={40} round={false} />
          </FacebookShareButton>

          <FacebookMessengerShareButton
            url={category !== null ? encodeURI(`${watchmojoUrl}play/${category}`) :  videoId !== null ?  encodeURI(`${watchmojoUrl}play/${videoId}`) : `${watchmojoUrl}play/${linkForKeyword}`}
            appId={'3084454008491892'}
            style={{ marginRight: '10px' }}
          >
            <FacebookMessengerIcon size={40} round={false} />
          </FacebookMessengerShareButton>
            
          <WhatsappShareButton
            title={category !== null ? `I tested my trivia power in the ${category} category and scored ${score}/10! Test your trivia power too!` : `I tested my trivia power and scored ${score}/10! Test your trivia power too!`}
            url={category !== null ? encodeURI(`${watchmojoUrl}play/${category}`) :  videoId !== null ?  encodeURI(`${watchmojoUrl}play/${videoId}`) : `${watchmojoUrl}play/${linkForKeyword}`}
            style={{ marginRight: '10px' }}
          >
            <WhatsappIcon size={40} round={false} />
          </WhatsappShareButton>

          <RedditShareButton
            title={category !== null ? `I tested my trivia power in the ${category} category and scored ${score}/10! Test your trivia power too!` : `I tested my trivia power and scored ${score}/10! Test your trivia power too!`}
            url={category !== null ? encodeURI(`${watchmojoUrl}play/${category}`) :  videoId !== null ?  encodeURI(`${watchmojoUrl}play/${videoId}`) : `${watchmojoUrl}play/${linkForKeyword}`}
            style={{ marginRight: '10px' }}
          >
            <RedditIcon size={40} round={false} />
          </RedditShareButton>

          <ViberShareButton
            title={category !== null ? `I tested my trivia power in the ${category} category and scored ${score}/10! Test your trivia power too!` : `I tested my trivia power and scored ${score}/10! Test your trivia power too!`}
            url={category !== null ? encodeURI(`${watchmojoUrl}play/${category}`) :  videoId !== null ?  encodeURI(`${watchmojoUrl}play/${videoId}`) : `${watchmojoUrl}play/${linkForKeyword}`}
            style={{ marginRight: '10px' }}
          >
            <ViberIcon size={40} round={false} />
          </ViberShareButton>

          <button
            onClick={handleCopyToClipboard}
            style={{
              marginLeft: '0',
              backgroundColor: '#61aefe',
              width: '40px',
              height: '40px',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <ContentCopyIcon fontSize={'medium'} />
          </button>
        </Box>
        {loggedIn === false || loggedIn === null ? (
          <Box
            pl={3}
            pt={3}
            mt={3}
            style={{
              backgroundColor: '#060081',
              height: '100%'
            }}
            display="flex"
            flexDirection="column"
          >
            <Typography variant="h5" style={{ fontSize: '24px', fontWeight: '600' }}>Sign up today</Typography>
            <Typography variant="body1" style={{ fontSize: '15px', lineHeight: '1.2' }}>
              Create an account to keep track of all your future trivia points
              and games!
            </Typography>
            <Button
              onClick={handleLogin}
              style={{
                backgroundColor: '#4198c7',
                width: '90%',
                height: '50px',
                color: '#fff',
                textTransform: 'none',
                borderRadius: '8px',
                marginTop: '4%',
                marginBottom: '40px',
              }}
            >
              Create account
            </Button>
          </Box>
        ) : (
          '')}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="url copied to clipboard"
        action={action}
      />
    </>
  )
}

export default Score
