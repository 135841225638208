import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  firstBox: {
    display:'flex',
    flexDirection:'column',
    backgroundColor: '#181818',
    color: '#fff',
    boxShadow: '1px #252525',
    width: '100%',
    height: '610px',
    [theme.breakpoints.up(900)]: {
      justifyContent:'center'
    }
  },
  triviaTime: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '27px',
    textAlign: 'center',
    marginTop: '10px',
  },
  info: {
    marginTop: '10px',
    marginLeft: '60px',
    marginRight: '60px',
    marginBottom: '15px',
    [theme.breakpoints.down(600)]: {
      marginLeft: '25px',
      marginRight: '25px',
    },
  },
  firstLine: {
    marginLeft: '60px',
    marginRight: '44px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      marginLeft: '25px',
      marginRight: '25px',
    },
  },
  surpriseMeBox: {
    marginLeft: '60px',
    marginRight: '44px',
    marginBottom: '80px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      marginLeft: '25px',
      marginRight: '25px',
    },
  }
}))

export default useStyles
