
const initialState = {
  badge: null,
  profilePic: null,
  // correctAns: null,
  // gamesPlayed: null,
}
  
const GameOverReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'HANDLE_BADGE':
    return Object.assign({}, state, {
      badge: action.data,
      profilePic: state.profilePic,
      // correctAns: state.correctAns,
      // gamesPlayed: state.gamesPlayed,
    })
  
  case 'HANDLE_PROFILEPIC':
    return Object.assign({}, state, {
      badge: state.badge,
      profilePic: action.data,
      // correctAns: state.correctAns,
      // gamesPlayed: state.gamesPlayed,
    })
  
    // case 'HANDLE_CORRECTANS':
    //   return Object.assign({}, state, {
    //     badge: state.badge,
    //     profilePic: state.profilePic,
    //     correctAns: action.data,
    //     gamesPlayed: state.gamesPlayed,
    //   })
  
    // case 'HANDLE_GAMESPLAYED':
    //   return Object.assign({}, state, {
    //     badge: state.badge,
    //     profilePic: state.profilePic,
    //     correctAns: state.correctAns,
    //     gamesPlayed: action.data,
    //   })

  default:
    return state
  }
}
  
//adding the badge
export const addBadge = (val) => {
  return {
    type: 'HANDLE_BADGE',
    data: val
  }
}
  
//adding the profile pic
export const addProfilePic = (val) => {
  return {
    type: 'HANDLE_PROFILEPIC',
    data: val
  }
}
  
// //adding correct answ
// export const addCorrectAns = (val) => {
//   return {
//     type: 'HANDLE_CORRECTANS',
//     data: val
//   }
// }
  
// //adding games played
// export const addGamesPlayed = (val) => {
//   return {
//     type: 'HANDLE_GAMESPLAYED',
//     data: val
//   }
// }
  
export default GameOverReducer
  