
const initialState = {
  answers: [],
  correctAnswer: [],
}

const AnswersReducer = (state = initialState, action) => {
  switch (action.type) {

  case 'GET_ANSWERS':
    return Object.assign({}, state, {
      answers: action.data,
      correctAnswer: state.correctAnswer
    })

  case 'GET_CORRECT_ANSWER':
    return Object.assign({}, state, {
      answers: state.answers,
      correctAnswer: action.data
    })

  default:
    return state
  }
}

//get all answers
export const handleAnswers = (result) => {
  return async dispatch => {
    dispatch({
      type: 'GET_ANSWERS',
      data: result
    })
  }
}

//get correct answer
export const handleCorrectAns = (result) => {
  return async dispatch => {
    dispatch({
      type: 'GET_CORRECT_ANSWER',
      data: result
    })
  }
}


export default AnswersReducer
