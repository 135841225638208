/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import useStyles from './useStyles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { handleQuestions, handleSession } from '../../reducers/QuestionsReducer'
import { addTitle, addVideoTitle, addVideoId } from '../../reducers/MiscReducer'
import { addToken } from '../../reducers/TokenReducer'
import getAll from '../../services/questions'
import { useLocation } from 'react-router-dom'
import { handleScore } from '../../reducers/MiscReducer'
import { handleGameOver } from '../../reducers/MiscReducer'
import { useHistory } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'
import { addBadge,addProfilePic } from '../../reducers/GameOverReducer'

//required alert func for snackbar message
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const KeywordLanding = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [ locationKeys, setLocationKeys ] = useState([])

  //getting questions from the redux store
  let questions = useSelector(({ questions }) => {
    return questions.questions
  })

  //getting category from store
  let category = useSelector(({ misc }) => {
    return misc.category
  })

  //get logged status from store
  let logged = useSelector(({ token }) => {
    return token.loggedIn
  })

  const search = useLocation().search
  /**
   * 
   * @params loggedIn, suggestionTitle and token from the url as params
   * 
   */
  useEffect(() => {
    const logged = new URLSearchParams(search).get('loggedIn')
    const suggestionTitle = new URLSearchParams(search).get('suggestionTitle')
    const videoTitle = new URLSearchParams(search).get('videoTitle')
    const videoId = new URLSearchParams(search).get('videoId')
    const token = new URLSearchParams(search).get('token')

    dispatch(addToken(token, logged)) 
    dispatch(addTitle(suggestionTitle))
    dispatch(addVideoTitle(videoTitle))
    dispatch(addVideoId(videoId))
    
    //fetching questions for the particular keyword
    async function getQuestions() {
      const result = await getAll(null, token)
      //sending questions to the redux store
      dispatch(handleQuestions(result.questions))
      dispatch(handleSession(result.sessionId))
    }
    getQuestions()
  }, [])

  
  //resetting score and gameover varibles to default
  useEffect(() => {
    dispatch(handleScore(0))
    dispatch(handleGameOver(false))
    dispatch(addProfilePic(null))
    dispatch(addBadge(null))
  }, [])

  //sending actions to php page for resizing the iframe height
  useEffect(() => {
    let message = { action: 'resize', height : '470px' }
    window.top.postMessage(message, '*')
  }, [])

  useEffect(() => {
    let message = { action: 'onload' }
    window.top.postMessage(message, '*')
  }, [])

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ ...keys ]) => keys)

          // Handle forward event
          //if user clicks forward button inside this keyword trivia page, will be redirected to home page accordingly
          category !== null ? window.top.location.href=`${process.env.REACT_APP_WATCHMOJO_URL}play` :  window.top.location.href=`${process.env.REACT_APP_WATCHMOJO_URL}suggest`
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          // Handle back event
        }
      }
    })
  }, [ locationKeys, ])

  const handleLogin = () => {
    let message = { action: 'login' }
    window.top.postMessage(message, '*')
  }

  return (
    <>
      <CssBaseline />
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box className={classes.secondBox}>
          <Typography variant="h6" className={classes.suggestionTitle}>
            It's Trivia Time
          </Typography>
          <Typography
            variant="body2"
            className={classes.descrip}
          >
            {`We've curated 10 questions related to the title above. Points won will go
            toward your Mojo points! Are you ready to have some fun and test
            your knowledge?`}
          </Typography>
        </Box>
        {
          questions.length > 1 ?
            <Link
              to={{
                pathname: '/play'
              }}
              style={{ textDecoration: 'none', minWidth: '100%' }}
            >
              <Typography
                className={classes.buttonTypog}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  style={{ marginBottom: logged ? '60px' : '12px' }}
                >
                  Play Now
                </Button>
              </Typography>
            </Link>
            :
            <Alert severity="error">Getting questions ... </Alert>
        }
        <Box>
          {logged ? (
            ''
          ) : (
            <Typography
              className={classes.loginToKeepTypo}
            >
              <button onClick={handleLogin} style={{ cursor: 'pointer', background: 'none', color: '#fff', border: 'none' }}>
                    Login to keep your scores!
              </button>
            </Typography>
          )}
        </Box>
      </Box>
    </>
  )
}

export default KeywordLanding
